<template>
  <div>
    <van-nav-bar title="预约信息" left-text="返回" @click-left="back" left-arrow />
    <!-- 主体 -->
    <div class="login_form">
      <van-cell class="doctor-info">
        <template #title>
          <div class="flex-row">
            <div>
              <van-image :src="doctor.avatar" class="doctor-avatar-image" />
            </div>
            <div class="flex1 flex-column justify-around" style="padding: 8px;margin-left: 4px;">
              <p class="bold" style="font-size:1.4em;">{{doctor.name}}</p>
              <p style="font-size:1.1em;">{{doctor.title}}</p>
            </div>
          </div>
        </template>
      </van-cell>
      <van-cell class="mt-10 black" title="预约姓名" :value="reserveInfo.username"></van-cell>
      <van-cell class="black" title="手机号" :value="reserveInfo.mobile"></van-cell>
      <van-cell class="mt-10 black" title="就诊类型" :value="reserveInfo.typeName"></van-cell>
      <van-cell title="就诊时间" :value="reserveInfo.time" class="time"></van-cell>
      <van-cell class="black" title="就诊费用">
        <span>￥</span>
        <span style="font-size:22px;">{{reserveInfo.fee}}</span>
      </van-cell>
      <div
        style="color:red;text-align:center;margin-top:10px;"
        v-if="reserveInfo.voilateTimes > 0"
      >违约未到次数已达{{reserveInfo.voilateTimes}}次，再违约{{reserveInfo.leftTimes}}次将无法预约。</div>
      <!-- <van-button class="logout" @click="logout" block round type="danger" >退出登录</van-button> -->

      <van-button type="info" class="bottom_btn" @click="confirmPay">确认支付</van-button>
    </div>
  </div>
</template>
<!--微信JS API -->
<script type="text/javascript" src=" https://res.wx.qq.com/open/js/jweixin-1.0.0.js"></script> 
<script>
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";
import { Toast } from "vant";

export default {
  components: {
    Footer,
  },

  computed: {
    ...mapGetters(["userId", "isLogin"]),
  },

  data() {
    return {
      payParam: {},
      reserveInfo: {
        username: null,
        mobile: null,
        type: null,
        time: null,
        fee: 200,
        voilateTimes: 0,
      },
      doctor: {
        avatar: null,
        name: null,
        title: null,
        goodAt: null,
      },
    };
  },

  methods: {
    //确认支付
    confirmPay() {
      const toast = this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get("/reserve-bill/add", { params: this.reserveInfo })
        .then((res) => {
          toast.clear();
          if (res.code == "ok") {
            if (res.data == null) {
              //直接支付成功
              this.$dialog
                .alert({
                  closeOnPopstate: true,
                  message: "预约成功",
                })
                .then(() => {
                  sessionStorage.listChange = true;
                  this.$router.push("/reservation/history");
                });
            } else {
              //需要支付的
              this.payParam = res.data;
              if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                  document.addEventListener(
                    "WeixinJSBridgeReady",
                    this.onBridgeReady,
                    false
                  );
                } else if (document.attachEvent) {
                  document.attachEvent(
                    "WeixinJSBridgeReady",
                    this.onBridgeReady
                  );
                  document.attachEvent(
                    "onWeixinJSBridgeReady",
                    this.onBridgeReady
                  );
                }
              } else {
                this.onBridgeReady();
              }
            }
          } else {
            this.$dialog
              .alert({
                closeOnPopstate: true,
                message: res.msg,
              })
              .then(() => {});
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    onBridgeReady() {
      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.payParam.appId, //公众号名称，由商户传入
          timeStamp: this.payParam.timeStamp, //时间戳，自1970年以来的秒数 (java需要处理成10位才行，又一坑)
          nonceStr: this.payParam.nonceStr, //随机串
          package: this.payParam.package, //拼装好的预支付标示
          signType: "MD5", //微信签名方式
          paySign: this.payParam.sign, //微信签名
        },
        function (res) {
          //使用以下方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回  ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.success("支付成功");
            sessionStorage.listChange = true;
            setTimeout(function () {
              that.$router.push("/reservation/history");
            }, 1500);
          } else {
            Toast.success("支付失败");
          }
        }
      );
    },
    startReserve(item) {
      console.log(item.id);
    },
    onTypeChange(index) {
      console.log(index);
      this.timeList = this.allTimeList.filter(function (a) {
        return a.type == index;
      });
    },

    getVoilateTimes() {
      this.$axios
        .get("/wuser-reserve-times/getMy", {
          params: { token: this.$user.openId },
        })
        .then((res) => {
          this.reserveInfo.voilateTimes = res.data.voilateTimes;
          this.reserveInfo.leftTimes = res.data.leftTimes;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    back() {
      this.$back();
    },
    logout() {
      this.$store.commit("updateLogin", false);
      sessionStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
  },
  create() {},
  mounted() {
    this.reserveInfo.wuserId = this.$user.id;
    this.reserveInfo.username = this.$user.username;
    this.reserveInfo.mobile = this.$user.mobile;

    this.doctor = JSON.parse(sessionStorage.doctor);
    this.reserveInfo.doctorId = this.doctor.id;

    var time = JSON.parse(sessionStorage.time);
    this.reserveInfo.halfTime = time.halfTime;
    console.log("this.reserveInfo.halfTime==" + this.reserveInfo.halfTime);
    this.reserveInfo.startTime = time.startTime;
    this.reserveInfo.endTime = time.endTime;
    this.reserveInfo.arrangeTimeId = time.id;
    this.reserveInfo.arrangeId = time.arrangementId;
    this.reserveInfo.type = time.type;

    console.log(time);
    this.reserveInfo.fee = time.fee;
    this.reserveInfo.time = sessionStorage.reserveDate;
    this.reserveInfo.arrangeDate = this.reserveInfo.time.split(" ")[0];
    var timeStr = ["普通", "专家", "膏方"];
    this.reserveInfo.type = time.type;
    this.reserveInfo.typeName = timeStr[time.type];

    this.getVoilateTimes();
  },
};
</script>

<style lang="less" scoped>
/* 本页公共样式 */
.gray {
  color: #797d82;
}

.bott0 {
  bottom: 0;
}

.orange {
  color: #ff5f16;
}

.yellow {
  color: #ffb232;
}

.bd-gray {
  border-bottom: 1px solid #f5f5f5;
}

// 头部图片
.beijin {
  background: #fff;
  height: 200px;
  background-size: cover;
  margin-top: -44px;
}

// 用户名和头像
.users {
  top: 55px;
  left: 22px;
  position: absolute;

  // 头像
  .imege {
    height: 65px;
    border-radius: 50%;
  }

  .fff {
    font-size: 22px;
  }
}
// 头像
.imege {
  height: 65px;
  border-radius: 50%;
}

// 导航
.nav {
  height: 75px;

  .iconfont {
    font-weight: bold;
  }

  .cit {
    height: 26px;
  }
}

.logout {
  width: 80%;
  margin: 50px auto 0;
}
.timeLine {
  line-height: 36px;
}
.timeLine span {
  padding: 0 10px 0 10px;
}
// 主体
main {
  .list {
    height: 50px;

    .image {
      height: 20px;
    }
  }

  .foun {
    font-size: 12px;
  }
}

.login_form {
  font-size: 20px;
}

/* 底部 */
.footer {
  height: 50px;
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
.van-cell__value {
  min-width: 70%;
  span {
    display: inline-block;
    text-align: left;
    word-break: break-all;
  }
}

.time .van-cell__value {
  color: #ff8c00;
}
.black .van-cell__value {
  color: #000000;
}

.bottom_btn {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>